import React from 'react';

export const Contact = () =>{
  return (
    <div className="page-content">
      <div className='content-block content-block-inner'>

      <h1>Impressum</h1>
      
      <h3>Angaben gem. § 5 des TMG:</h3>

      Thomas Amthor<br />
      c/o Postflex #3665<br />
      Emsdettener Str. 10<br />
      48268 Greven<br />
      (Keine Pakete oder Päckchen - Annahme wird verweigert!)<br />
      <br />
      Telefon: +49 (0) 40 52471468 <br />
      E-Mail: kontakt@hallobaum.de<br />
      <br />
{/*      USt-IdNr.: DE360486057 
      <br />
      <br />*/}

      <h3>Konzept und Programmierung, redaktionell verantwortlich</h3>
      Thomas Amthor <br />
      (Adresse s.o.) <br />
      <br />
      
      <h3>Logoentwicklung und UX-Design der App</h3>
      Liam Isler, <a target="_new" href="https://the-colorful-brain.jimdofree.com/" rel="nofollow">The Colorful Brain</a> <br />
      <br />

      <h3>Haftungshinweis</h3>
      Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links und eingebundener Karten.
      Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber:innen verantwortlich.

      </div>
    </div>

  );
}

