import React from 'react';
import NabuLogo from '../assets/images/NABU_Logo_RGB.png';
import TCBLogo from '../assets/images/the-colorful-brain-logo.png';
import TreeLogo from '../assets/images/TreeLogo30_medium.webp';
import Screenshot from '../assets/images/Screenshot_0.6.1_HH_Firefox_compr.png';
import CityMap from '../assets/images/city_map.png';
import TitleImage from '../assets/images/iStock-1869387564-compr.webp';
//import { TbExternalLink as IconExternalLink } from 'react-icons/tb';
import { KoFiButton, ZoomableImage } from '../components';
import GoogleBanner from '../assets/images/google-play-badge_DE.png';
import AppleBanner from '../assets/images/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg';
import PWABanner from '../assets/images/pwa-badge.png';

export const MainPage = ( ) => {
    return (
    <div className="page-content">

        <div className='content-block-top'>
            <img className="content-image-full" src={TitleImage} alt="Titelbild: Bäume entlang Straßen in Hamburg"/>
            <div className="image-credits">
                Foto: iStock.com/Canetti
            </div>
            <div className="content-image-full-overlay">
                <div className="text-title-h1">HALLO BAUM</div>
                <div className="text-title-h2">die Straßenbaum-App</div>
            </div>
            <svg viewBox="-2 0 37.05 37.05" height="120px" width="120px" className="speech-bubble-connector">
           <path transform="translate(-37.041667,-21.166667)" fill="currentColor"
             d="m 52.916667,21.166667 c 0,0 -15.875,23.8125 -15.875,31.75 h 31.75 c -15.875,-1.322917 0,0 -13.229167,-1.322917 C 41.010417,47.625 52.916667,21.166667 52.916667,21.166667 Z"
               />
        </svg>

        </div>

        <div className='content-block'>
          <div className='content-block-inner'>
            <h1>KENNST DU DEN BAUM AN DEINER STRA&#7838;E?</h1>
            <div className="content-block-image">
                <img width="250px" height="250px" src={TreeLogo} alt="Hallo Baum Logo" />
            </div>
            <div className="content-block-text">
                <p>
                Die Bäume in deiner Stadt leisten wichtige Arbeit: Sie kühlen im Sommer ihre
                Umgebung, spenden Schatten, binden CO<sub>2</sub> und ﬁltern Schadstoﬀe aus der Luft.
                Außerdem sind sie Lebensraum für viele Tiere. Die meisten Straßenbäume
                stehen auch schon viele Jahrzehnte an ihrem Platz und haben einiges erlebt.
                </p>
                <p>
                Das Projekt "Hallo Baum" soll das Bewusstsein für die Leistung und die Wichtigkeit unserer Stadtbäume fördern.
                Dazu wurden öffentlich zugängliche Daten über Stadtbäume und Bodenbeschaffenheit zusammengetragen und
                verschiedene Merkmale jedes Baumes daraus berechnet. Diese Informationen kannst du jetzt bei einem Spaziergang
                durch die Stadt über die App abrufen.
                </p>
            </div>
          </div>
        </div>

        <div className='content-block content-block-background-dark'>
          <div className='content-block-inner'>
            <h2>HALLO BAUM &ndash; DIE APP</h2>
            <div className="content-block-text">
                <p>
                Stelle dich einfach neben einen Straßenbaum, tippe auf die "Hallo Baum"-Taste
                und erfahre interessante Details über deinen Baum!
                </p>
                <p>
                Jetzt bei Google Play und im App Store verfügbar!
                Hallo Baum kann alternativ auch
                unter <a href="https://hallobaum.de/app" target="_blank" rel="noreferrer">hallobaum.de/app</a> direkt 
                im Browser genutzt oder von dort aus als Progressive Web App installiert werden.
                </p>
                <center>
                  <a href="https://play.google.com/store/apps/details?id=de.hallobaum.app" target="_blank" rel="noreferrer" className="no-link-icon"><img src={GoogleBanner} className="store-banner-google" alt="Google Play banner"/></a>
                  <a href="https://apps.apple.com/de/app/hallo-baum/id6476420845" target="_blank" rel="noreferrer" className="no-link-icon"><img src={AppleBanner} className="store-banner-apple" alt="App Store banner"/></a>
                  <a href="https://hallobaum.de/app" target="_blank" rel="noreferrer" className="no-link-icon"><img src={PWABanner} className="pwa-banner" alt="PWA banner"/></a>

                </center>
            </div>
            <div className="content-block-image">
                    <ZoomableImage src={Screenshot} alt="Hallo Baum App Screenshot" />
            </div>
          </div>
        </div>


        <div className='content-block'>
          <div className='content-block-inner'>
            <h1>WO KANN ICH HALLO BAUM NUTZEN?</h1>
            <div className="content-block-image">
                <ZoomableImage src={CityMap} alt="Übersichtskarte verfügarer Städte" />
            </div>
            <div className="content-block-text">
                <p>
                Die Hallo Baum App funktioniert bereits in <b>Berlin, Dortmund, Dresden, Duisburg, 
                Frankfurt am Main, Gelsenkirchen, Halle (Saale), Hamburg, Köln, Leipzig, Magdeburg, Norderstedt, Rostock,
                und Würzburg</b>.
                <br />Außerdem ist der Baumbestand des <b>Arboretums Goldboden</b> in Winterbach bei Stuttgart integriert.
                </p>
                <p>
                Die Integration weiterer Städte ist in Planung. Dazu ist es notwendig, dass die Stadtverwaltungen
                alle erforderlichen Daten (Lage, Art und Maße der Bäume)
                aus den städtischen Baumkatastern digital zur Verfügung stellen. Leider tun dies noch nicht alle.                
                </p>
            </div>
          </div>
        </div>


{/*
        <div className='content-block'>
            <h1>Offene Datenquellen</h1>
            <div className="content-block-image">
                <img width="200px" src={TreeLogo} alt="Hallo Baum Logo" />
            </div>
            <div className="content-block-text">
            Die Informationen über den Baum und seine Umgebung kommen aus
Datenbanken, die deine Stadt öﬀentlich zur Verfügung stellt. Daraus werden
dann einige interessante Dinge berechnet, z.B. wie viel CO<sub>2</sub> dein Baum schon
aus der Luft aufgenommen hat.
            </div>
        </div>
*/}

        <div className='content-block content-block-background-dark'>
          <div className='content-block-inner'>
            <h2>DANKESCHÖN</h2>
                <p>
                Dieses Projekt wurde unterstützt durch den NABU Hamburg und The Colorful Brain (externe Links).
                </p>
                <a target="_new" className="no-link-icon" href="https://hamburg.nabu.de/" rel="nofollow">
                    <img className="mobile-centered" width="150px" src={NabuLogo} alt="NABU-Logo, Nutzung mit Genehmigung" />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a target="_new" className="no-link-icon" href="https://the-colorful-brain.jimdofree.com/" rel="nofollow">
                    <img className="mobile-centered" width="150px" src={TCBLogo} alt="Logo von The Colorful Brain" />
                </a>

                <p>
                Willst du die Weiterentwicklung von Hallo Baum auch unterstützen? <br />
                Dann spendiere doch
                einen Kaffee über Ko-fi (externer Link):
                </p>
                <div className="mobile-centered" >
                    <a target="_new" className="no-link-icon" href="https://ko-fi.com/thomasamthor" rel="nofollow"><KoFiButton /></a>
                </div>

          </div>
        </div>
    </div>
    );

}
