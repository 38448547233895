import React from 'react';
import { TbExternalLink as IconExternalLink } from 'react-icons/tb';

export const DataPrivacy = () =>{
  return (
    <div className="page-content text-hyphenated">
      <div className='content-block content-block-inner'>

<h1>Datenschutz&shy;erkl&auml;rung</h1>

Diese Datenschutzerklärung bezieht sich auf die Webseite <span className='no-hyphenation'>hallobaum.de</span>, 
die darüber erreichbare Web-App <span className='no-hyphenation'>hallobaum.de/app</span> sowie die installierte
native App "Hallo Baum".

<h2>1. Für den Datenschutz Verantwortlicher</h2>

Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber:<br />
Thomas Amthor<br />
c/o Postflex #3665<br />
Emsdettener Str. 10<br />
48268 Greven<br />
Telefon: +49 (0) 40 52471468<br />
E-Mail: kontakt@hallobaum.de<br />

<h2>2. Rechte der Betroffenen</h2>
Sie haben jederzeit das Recht,
<ul>
    <li>unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten,</li>
    <li>die Berichtigung oder Löschung Ihrer Daten zu verlangen,</li>
    <li>Ihre Einwilligung zur Datenverarbeitung jederzeit mit Wirkung für die Zukunft zu widerrufen,</li>
    <li>Ihr Recht auf Datenübertragbarkeit geltend zu machen, sofern technisch möglich,</li>
    <li>unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen,</li>
    <li>sich bei der zuständigen Aufsichtsbehörde zu beschweren.</li>
</ul>

<h2>3. Datenerfassung auf dieser Website</h2>
Durch einen Besuch unserer Webseite werden teilweise personenbezogene Daten verarbeitet,
die für die Funktionsweise der Webseite notwendig sind und die unabhängig von der Nutzung der Webseite allein
durch den Aufruf der Seite verarbeitet werden (z.B. Server-Log-Files).
Andere Daten werden nur durch eine bestimmte Nutzung, etwa durch das Ausfüllen eines Kontaktformulars, verarbeitet.
Im Einzelnen:

<h3>Server-Log-Dateien</h3>
Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, 
die Ihr Browser automatisch an uns übermittelt. Dies sind:
<ul>
    <li>Browsertyp und Browserversion</li>
    <li>verwendetes Betriebssystem</li>
    <li>Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>Datum und Uhrzeit der Serveranfrage</li>
    <li>IP-Adresse</li>
</ul>
Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. 
Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der 
Optimierung seiner Website - hierzu müssen die Server-Log-Files erfasst werden.

<h3>Standortdaten</h3>
Unser Dienst dient dazu, Auskunft über Bäume in der unmittelbaren Umgebung des Betrachters zu geben. 
Daher benötigt die Webseite die Standortdaten des Betrachters. Es ist nicht möglich, sich Informationen über 
Bäume anzeigen zu lassen, in deren Nähe sich der Betrachter nicht befindet. Dadurch soll gewährleistet werden, 
dass der Betrachter sich mit den Bäumen in seiner Nähe beschäftigt und mit einem offenen Blick die Natur 
in seiner Umgebung wahrnimmt.<br />
Wir verwenden die Standortdaten nur aus dem oben genannten Grund. Die Standortdaten werden nicht gespeichert
und auch nicht an Dritte weitergegeben (außer, dies ist technisch notwendig, etwa an unseren Hosting-Dienstleister 
als Serverbetreiber). Der Standort wird auf Grundlage unseres berechtigten Interesses gemäß Art. 6 Abs. 1 lit. b DSGVO 
verarbeitet, um den Betrachter auf seine Umgebung aufmerksam zu machen. Indem die Standortdaten weder gespeichert noch für 
andere Zwecke verarbeitet werden, stellen wir sicher, dass die Datennutzung möglichst minimal ist.

<h3>Nutzungsinformationen</h3>
Zum Zwecke der technischen Wartung und Verbesserung der App speichern wir, wie viele Abfragen unserer Baum-Datenbank
pro Tag und pro Stadt erfolgt sind. Es wird dabei nur die tägliche Gesamtzahl der Anfragen pro Stadt erhoben.
Informationen über einzelne Abfragen, wie der genaue Standort oder Zeitpunkt, oder andere Informationen, die eine Zuordnung
zu einer Person zulassen würden, werden nicht gespeichert.<br />
Einige Informationen zur Nutzung der Web-App werden lokal im Browser gespeichert, aber nicht an unseren
Server oder an andere Dienste übertragen. Es handelt sich dabei um Daten, die zur Bereitstellung einiger Funktionen der
Webseite notwendig sind. Dies sind:
<ul>
  <li>Informationen darüber, ob und wann Hinweise und Popup-Fenster bereits gelesen wurden, um sie nicht erneut anzuzeigen.</li>
  <li>Zuletzt genutze Versionsnummern der Dienste, um Updates zu erkennen</li>
{
//  <li>Zuletzt besuchte Bäume mit Position und Zeitstempel, um sie in der Web-App wieder anzeigen zu können</li>
}
</ul>


{/*
<h3>Kontaktformular</h3>
Wer uns über das Kontaktformular eine Nachricht schickt, muss hierzu einen Namen (muss nicht der Klarname sein, 
wäre aber nett), eine E-Mail-Adresse für die weitere Kommunikation sowie den Nachrichtentext senden.
Die Daten verarbeiten wir nur für die Bearbeitung bzw. Beantwortung der Nachricht.<br />
Die Verarbeitung dieser Daten erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf unserem berechtigten Interesse 
an der effektiven Bearbeitung der an uns gerichteten Anfragen.<br />
Die im Kontaktformular eingegebenen Daten verbleiben bei uns, bis wir zur Löschung aufgefordert werden oder der Zweck 
für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung der Anfrage). 
Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.<br />
Entsprechendes gilt für eine Kontaktaufnahme mittels E-Mail, Telefon oder Telefax.
*/}

<h2>4. Weitergabe von Daten an Dritte</h2>
„Wir geben keine personenbezogene Daten an Dritte weiter“ - dieser Satz findet sich häufig in Datenschutzerklärungen.
Wir sind genauer und sagen: Wir geben Daten weiter, wo dies technisch notwendig ist, bleiben aber immer für 
die Datenverarbeitung verantwortlich und arbeiten nur mit technischen Partnern zusammen, die zuverlässig sind 
und die geltenden Datenschutzbestimmungen beachten. Unsere Dienstleister sind rechtlich betrachtet Auftragsverarbeiter, 
die mittels Auftragsverarbeitungsvertrag rechtlich zu einer ordnungsgemäßen, weisungsgebundenen
Datenverarbeitung verpflichtet sind.

<h3>Hosting</h3>
Unsere Webseite wird bei dem Hosting-Anbieter Strato (Strato AG, Otto-Ostrowski-Straße 7, 10249 Berlin) gespeichert. 
Alle Daten, die durch den Besuch unserer Webseite verarbeitet werden, erfasst Strato für uns. 
Das betrifft beispielsweise die Daten aus den Server-Log-Files (s. Punkt 3).
Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. 
Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. 
Weitere Informationen zur Datenverarbeitung bei Strato finden sich in der Datenschutzerklärung des Unternehmens 
(aktuell abrufbar unter <a href="https://www.strato.de/datenschutz/" target="_new" rel="nofollow">https://www.strato.de/datenschutz</a>, 
Stand 5. Februar 2024).

<h3>OpenStreetMap</h3>
Wir nutzen den Kartendienst von OpenStreetMap (OpenStreetMap Foundation, St John's Innovation Centre, Cowley Road,
Cambridge, CB4 0WS, Großbritannien). OpenStreetMap halten wir für eine datenschutzfreundlichere Alternative zu Google Maps. 
Nach dem Ausscheiden des Vereinigten Königreichs aus der Europäischen Union hat die Europäische Kommission das 
Vereinigte Königreich als datenschutzrechtlich sicheren Drittstaat anerkannt, so dass eine Übermittlung von 
personenbezogenen Daten einer Übermittlung innerhalb der EU rechtlich gleichgestellt ist.<br />
Bei der Nutzung der OpenStreetMap-Karten wird eine Verbindung zu den Servern der OpenStreetMap-Foundation hergestellt. 
Dabei werden u.a. die IP-Adresse des Nutzers sowie weitere personenbezogene Daten an OpenStreetMaps weitergeleitet. 
OpenStreetMap speichert hierzu unter Umständen Cookies in Ihrem Browser oder setzt vergleichbare Wiedererkennungstechnologien ein.<br />
Die Nutzung von OpenStreetMap erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und einer 
leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes 
Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.

{/*
<h3>MathJax</h3>
MathJax, ein Dienst von NumFOCUS, P.O. Box 90596, Austin, TX 78709, USA, dient der Darstellung mathematischer Symbole und Gleichungen. 
Bei Aufruf der Unterseite „Berechnungsmodelle“ wird eine Verbindung zum Server cdnjs.cloudflare.com hergestellt, 
um die Symbole und Gleichungen zu laden. Im Rahmen dieser Verbindung wird die IP-Adresse des Nutzers übermittelt.<br />
Die Nutzung von MathJax erfolgt auf Grundlage unseres berechtigten Interesses an einer einheitlichen und ansprechenden 
Darstellung unseres Online-Angebots im Sinne des Art. 6 Abs. 1 lit. f DSGVO. 
Weitere Informationen finden sich unter https://www.mathjax.org.
*/}

<h2>5. Speicherdauer</h2>
Soweit für einzelne Verarbeitungszwecke keine abweichende Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen 
Daten bei uns, bis der Zweck für die Datenverarbeitung erreicht wurde oder entfallen ist. Durch ein berechtigtes 
Löschersuchen oder den Widerruf der Einwilligung zur Datenverarbeitung können die Daten gelöscht werden, sofern keine 
anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten vorliegen, etwa steuer- oder 
handelsrechtliche Aufbewahrungsfristen. Im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.

<h2>6. Verlinkung externer Inhalte</h2>
Links auf externe Inhalte sind entweder mit dem Symbol <IconExternalLink /> oder dem Text "externer Link" kenntlich gemacht.
Beim Anklicken externer Links werden Sie auf eine Webseite weitergeleitet, die außerhalb des Einflussbereichs des Webseitenbetreibers von
hallobaum.de liegt. Wie bei jedem Aufruf von Webseiten werden dabei auch personenbezogene Daten, so wie Ihre IP-Adresse,
an den externen Webserver übertragen. Es gilt dann die jeweilige Datenschutzerklärung des externen Dienstanbieters.


{/*
<p>Angepasst nach Vorlage von: <a href="https://www.e-recht24.de" target="_new" rel="nofollow">https://www.e-recht24.de</a></p>
*/}
      </div>
    </div>
  );
}
