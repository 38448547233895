import React from 'react';
import { MathJax, MathJaxContext } from "better-react-mathjax";

const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    }
  };
  
export const Science = () =>{
  return (
    <div className="page-content text-hyphenated">
        <div className='content-block content-block-inner'>

        <h1>Funktionsweise der "Hallo Baum"-App</h1>

        Hallo Baum nutzt öffentlich zugängliche Daten aus dem Baumkataster deiner Stadt. Darüber wird der Baum
        identifiziert, vor dem du gerade stehst. Neben dem Alter und der Art des Baumes sind meist auch Messwerte
        wie Stammdurchmesser, Höhe oder Kronendurchmesser vorhanden. In manchen Städten gibt es noch weitere Informationen,
        beispielsweise zum Versiegelungsgrad des Bodens am Baumstandort.
        Aus all diesen Daten berechnet Hallo Baum, welche Leistungen für dich die Umwelt jeder einzelne Baum erbringt.<br />
        <br />
        Die Berechnungen basieren auf aktueller wissenschaftlicher Fachliteratur. Sie sollen die Leistungen
        des Baumes so gut wie möglich abschätzen, soweit es die vorhandenen Informationen zulassen. Im folgenden werden
        die Berechnungsmethoden etwas genauer erläutert.<br /> 
        <br />


        <MathJaxContext version={3} src={process.env.PUBLIC_URL + "/mathjax/tex-chtml.js"} config={config}>

<h2>CO<sub>2</sub>-Bindung</h2>

Die Menge des aufgenommenen CO<sub>2</sub> wird aus der Biomasse des Baums berechnet.
Die Biomasse wiederum kann anhand der Baummaße (Stammumfang und Höhe) über sogenannte allometrische Funktionen abgeschätzt werden.<br />

Jedes Kohlenstoffatom, das sich im Baum befindet, wurde einmal aus einem CO<sub>2</sub>-Molekül entnommen.
Ein CO<sub>2</sub>-Molekül ist etwa 3,67-mal so schwer wie das darin enthaltene Kohlenstoffatom.
Für jedes Kilogramm Kohlenstoff im Baum wurde also einmal 3,67 kg Kohlendioxid aus der Luft aufgenommen [4].
Da die Biomasse <MathJax inline>{"\\(B\\)"}</MathJax> des Baums etwa zur Hälfte aus Kohlenstoff besteht [3], ergibt sich für die aufgenommene Masse CO<sub>2</sub> also
<MathJax hideUntilTypeset={"first"}>
{`$$M_{\\mathrm{CO_2}} = 3,67 {B \\over 2}.$$`}
</MathJax>
Die Biomasse selbst wird über allometrische Funktionen berechnet, die je nach verfügbaren Daten unterschiedliche Form haben können.
In dieser ersten Version wird eine Biomassefunktion der Form
<MathJax hideUntilTypeset={"first"}>
{`$$B = \\beta_0 d^{\\beta_1}$$`}
</MathJax>
angenommen, wobei $d$ der Stammdurchmesser in 1,30m Höhe ist.
Die Parameter <MathJax inline>{"\\(\\beta_0\\)"}</MathJax> und <MathJax inline>{"\\(\\beta_1\\)"}</MathJax> sind abhängig von der Baumart.
Für einige europäische Bäume finden sich dazu Schätzwerte in [1] und [2].
Nicht alle typischen Straßenbäume sind dort tabelliert, so dass bei einigen Arten Parameter einer tabellierten Art mit ähnlichem Wuchsverhalten übernommen werden [5, 6].
<br />
<br />
Die Biomasse wird anschließend noch mittels empirischer Faktoren korrigiert, um auch den Anteil der Biomasse in den Wurzeln sowie der Effekt der
Baumpflegeschnitte in der Stadt zu berücksichtigen [7].<br />
Eine präzisere allometrische Funktionsberechnung wird noch ergänzt, insbesondere für Bäume, deren Höhe ebenfalls bekannt ist und in die Rechnung eingehen kann.
<br />

<h2>Schattenwurf</h2>

Der sommerliche Schattenwurf wird aus dem Durchmesser $D$ der Baumkrone abgeschätzt (soweit bekannt).
Dabei wird vereinfachend angenommen, dass die Baumkrone in etwa kugelförmig ist und der Winkel des Sonneneinfallswinkel
an einem Sommernachmittag etwa 45 Grad beträgt.
Somit ergibt sich für die maximale Schattenfläche \(A\) am Boden
<MathJax hideUntilTypeset={"first"}>
{`$$A = \\sqrt{2}\\, \\pi \\left({D \\over 2}\\right)^2.$$`}
</MathJax>
Bei Kronenformen, die sehr von einer Kugel abweichen, ist dieses Ergebnis natürlich entsprechend ungenau.
Da ein Schattenwurf auf dem Boden angenommen wird, ist bei einem tatsächlichen Schattenwurf auf eine andere Oberfläche
die Fläche des Schattens ggf. geringer. Daher wird die angegebene Fläche auch noch abgerundet.
Bei einigen seltener vorkommenden Bäumen und Sträuchern, deren Kronenform nicht bekannt ist, wird kein Schattenwurf angegeben.
<br />

<h2>Sauerstoffproduktion</h2>

Die durchschnittliche Sauerstoffproduktion pro Jahr läßt sich aus der Zuwachsrate der Biomasse bestimmen, denn beim Einlagern von Kohlenstoff
wird ja der Sauerstoff aus dem CO<sub>2</sub> abgegeben [8].
Zur Bestimmung der Zuwachsrate (Änderung des Stammdurchmessers pro Jahr) wurde zunächst für jede Spezies separat die logarithmische
Fitfunktion an Daten aus dem Hamburger Baumkataster angepasst, um den Zusammenhang zwischen Standalter and Stammdurchmesser der Bäume zu ermitteln.
Durch die Wahl des Datensatzes wurde gleichzeitig den speziellen Wachstumsbedingungen der städtischen Umgebung Rechnung getragen.
Die Zuwachsrate ergibt sich dann als Ableitung der Funktion für den Stammdurchmesser nach der Zeit. Daraus lässt sich schliesslich
der Zuwachs $\Delta d$ pro Jahr wieder als Funktion des Stammdurchmessers ausdrücken,
<MathJax hideUntilTypeset={"first"}>
{`$$\\Delta d (d) = a \\, e^{(b - d)/a},$$`}
</MathJax>
wobei $a$ und $b$ die Fitparameter für die jeweilige Baumspezies sind. Typische Werte für $\Delta d$ liegen im Bereich von einigen mm pro Jahr.
Mit den allometrischen Funktionen (s.o.) wird dieser Wert dann in die Masse des zugewachsenen Kohlenstoffs übersetzt. Da pro eingelagertem
Kohlenstoffatom ein O<sub>2</sub>-Molekül aus dem CO<sub>2</sub> frei wird, ist die Masse des erzeugten Sauerstoffs das 2,67-fache der Masse
des Kohlenstoffs. Bei einem jährlichen Biomassezuwachs $\Delta B$ ergibt sich also
<MathJax hideUntilTypeset={"first"}>
{`$$\\Delta M_{\\mathrm{O_2}} = 2,67 \\frac{\\Delta B}{2}.$$`}
</MathJax>
Unter der Annahme, dass wir 50% unserer Zeit in Ruhe und 50% unter moderater Anstrengung verbringen, atmen wir etwa 16m<sup>3</sup> Luft pro Tag ein und aus [9].
Davon sind etwa 5% der tatsächlich verwertete Sauerstoff, also 0,8m<sup>3</sup>. Mit der Dichte von Sauerstoff (1,429 kg/m<sup>3</sup>) kann man so
die benötigte Gesamtmasse an Sauerstoff pro Person und Jahr auf etwa 420kg schätzen. So lässt sich dann bestimmen, welchen Anteil des von einem
Menschen benötigten Sauerstoffs der Baum produziert.<br />
(Dabei ist natürlich zu beachten, dass wir für das Verbrennen fossiler Rohstoffe pro Jahr noch wesentlich mehr Sauerstoff benötigen als für unsere Atmung.)
<br />

<h2>Kühlleistung</h2>

Die Kühlleistung des Baumes hängt davon ab, wie viel Wasser an seinen Blattoberflächen verdunstet, denn beim Verdunsten nimmt das Wasser Wärme
aus der Umgebung auf und kühlt somit die Umgebung ab. Dazu muss man auch abschätzen, wie viel Wasser er überhaupt aus dem Boden aufnehmen kann.
Hierzu wird noch eine Berechnung ergänzt.
<br />

<h2>Luftfilterung</h2>

Der Baum kann auf den Blattoberflächen Schadstoff-Partikel und Gase (z.B. Stickoxide) aus der Luft binden.
Welche Menge an Schadstoffen auf diese Weise aus der Luft gefiltert werden können, hängt natürlich von der gesamten Blattfläche, der Oberflächenbeschaffenheit
und auch von der Zeit im Jahr ab, während der der Baum überhaupt Blätter hat.
Auch das Wetter (Luftbewegung, Temperatur) und die Wasserversorgung des Baumes (und damit die Öffnung der Poren) haben Einfluss auf die Schadstoff-Aufnahme.
Auch hierzu wird noch eine Berechnung ergänzt.

<br />

{/*
<h2>Wasserbedarf</h2>
<br />
  */}
<br />

<h2>Literatur</h2>

[1] Zell, J. Methoden für die Ermittlung, Modellierung und Prognose der Kohlenstoffspeicherung in Wäldern auf Grundlage permanenter Großrauminventuren. (2016) <a target="_new" href="https://doi.org/10.6094/UNIFR/11423">DOI: 10.6094/UNIFR/11423</a><br />
<br />
[2] Forrester, D. et al. Generalized biomass and leaf area allometric equations for European tree species incorporating stand structure, tree age and climate. Forest Ecology and Management 396 (2017) 160–175 <a target="_new" href="http://dx.doi.org/10.1016/j.foreco.2017.04.011">DOI: 10.1016/j.foreco.2017.04.011</a><br />
<br />
[3] Birdsey, R. A. Carbon storage and accumulation in United States forest ecosystems. U.S. Department of Agriculture, Gen. Tech. Rep. WO-59 (1992). <a target="_new" href="https://doi.org/10.2737/WO-GTR-59">DOI: 10.2737/WO-GTR-59</a><br />
<br />
[4] Bayerische Landesanstalt für Wald und Forstwirtschaft (LWF), <a target="_new" href="https://www.lwf.bayern.de/mam/cms04/service/dateien/mb-27-kohlenstoffspeicherung-2.pdf">Merkblatt 27</a>.<br />
<br />
[5] <a target="_new" href="https://www.waldwissen.net/de/lebensraum-wald/klima-und-umwelt/klimawandel-und-co2/kohlenstoffspeicher-baum">https://www.waldwissen.net/...</a><br />
<br />
[6] <a target="_new" href="https://www.cermeter-pflanzen.de/2018/12/22/wie-viel-co2-nimmt-ein-baum-auf/">https://www.cermeter-pflanzen.de/...</a><br />
<br />
[7] Nowak, D. J. et al. A Ground-Based Method of Assessing Urban Forest Structure and Ecosystem Services. Arboriculture & Urban Forestry (2008) 34(6):347-358. <a target="_new" href="https://doi.org/10.48044/jauf.2008.048">DOI: 10.48044/jauf.2008.048</a><br />
<br />
[8] Nowak, D. J. et al. Oxygen Production by Urban Trees in the United States. Arboriculture & Urban Forestry (2007) 33(3):220-226. <a target="_new" href="https://doi.org/10.48044/jauf.2007.026">DOI: 10.48044/jauf.2007.026</a><br />
<br />
[9] Pleil et al. The physics of human breathing: flow, timing, volume, and pressure parameters for normal, on-demand, and ventilator respiration.  J. Breath Res. (2021) 15:042002. <a target="_new" href="https://doi.org/10.1088/1752-7163/ac2589">DOI: 10.1088/1752-7163/ac2589</a><br />
<br />


</MathJaxContext>


        </div>
    </div>
  );
}
