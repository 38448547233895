import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as MyRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//import { hydrate, render } from "react-dom";

/*
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(  <React.StrictMode>
    <MyRouter basename="/">
      <App />
    </MyRouter>
    </React.StrictMode>
  , rootElement);
}
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <MyRouter basename="/">
    <App />
  </MyRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
