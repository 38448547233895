import React from "react";
import "./KoFiButton.css";
import IconKoFi from '../assets/images/kofi_s_logo_nolabel_small.png';
import { TbExternalLink as IconExternalLink } from 'react-icons/tb';

export const KoFiButton = ( ) => {

    return (
       <div className="kofi-button">
          <img className="kofi-button-icon" src={IconKoFi} alt="Ko-fi Kaffeetassen-Logo" />
          <div>
            <span className="kofi-text1">Unterstütze das Projekt</span><br />
            <span className="kofi-text2">auf Ko-fi</span>
            <span><IconExternalLink className="kofi-external-link-icon"/></span>
          </div>
       </div>
    )
}
