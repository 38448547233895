import React, { useState } from 'react';
import "./ZoomableImage.css";

export const ZoomableImage = ( { src, alt } ) => {

   const [zoomed, setZoomed] = useState(false); 

   const clickHandler = () => {
      setZoomed(!zoomed);
   }
   
   return (
      <>
         <img className="unzoomed-img"
              src={src}
              alt={alt}
              onClick={clickHandler}
            />
         { zoomed ?
            <>
            <div className="page-shade"
                 onClick={clickHandler}
            ></div>

            <img className={"zoomed-img" + (zoomed ? " zoomable-visible" : "")}
               src={src}
               alt={alt}
               onClick={clickHandler}
            />
            </>
             : null
         }

      </>

   )
}
