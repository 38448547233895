import React from 'react';
import NabuLogo from '../assets/images/NABU_Logo_RGB.png';
import { KoFiButton } from '../components';

export const About = () =>{
  return (
    <div className="page-content text-hyphenated">
      <div className='content-block content-block-inner'>
      { /*  <img src="https://vg04.met.vgwort.de/na/8bec67b082cc494494a24a3c05fbaa65" width="1" height="1" alt=""></img> */ }
        <h1>Die Idee</h1>
        <p>
        Die Bäume in unseren Städten sind wichtig. Wie wichtig, wurde gerade in den letzten Jahren in vielen
        Forschungsarbeiten zu den sogenannten "Ökosystem-Leistungen" der städtischen Vegetation herausgefunden.
        Darunter versteht man all das, was die Bäume für uns und unsere Umgebung leisten: Kühlung, Filterung von
        Schadstoffen und Verbesserung der Luftqualität, Bindung von Kohlenstoff, Steuerung des Wasserhaushalts,
        aber auch den Einfluss auf unser Wohlbefinden, wenn wir uns zwischen Bäumen aufhalten.
        </p>
        <p>
        Immer mehr Stadtverwaltungen stellen inzwischen Daten über die Vegetation, Bodenbeschaffenheit, Flächennutzung oder andere
        Informationen der Stadt in öffentlich zugänglichen Datenbanken zur Verfügung. Dazu gehört oft auch ein
        Baumkataster, in dem die Position und einige Messdaten zu den Stadtbäumen verzeichnet sind.
        </p>
        <p>
        Die Idee zu "Hallo Baum" entstand 2022 in einer kleinen Runde von Freunden, die sich für den Naturschutz
        in Hamburg einsetzen, nachdem wir das Hamburger Baumkataster entdeckt hatten. Wir fragten uns, wie man diese
        Informationen wohl nutzen könnte, um den Menschen in Hamburg die Wichtigkeit unserer Stadtbäume näherzubringen.
        Dabei sollten zwei Dinge im Vordergrund stehen: Ein Kennenlernen der Bäume, die vor der eigenen Haustür stehen (denn
        schützen kann man am besten das, was man auch gut kennt), und interessante Informationen darüber,
        was genau diese Bäume eigentlich in der Stadt leisten (denn ohne Fakten müsste man schließlich raten).
        </p>
        <p>
        Die "Hallo Baum"-App tut nun genau dies: Sie ermöglicht eine direkte Kommunikation mit dem Stadtbaum, vor dem man
        gerade steht, und sie zeigt gleichzeitig auch Hintergrundinformationen über genau diesen Baum. Diese gehen
        inzwischen weit über das hinaus, was direkt im Baumkataster zu finden ist. Basierend auf aktueller 
        Forschungsliteratur habe ich begonnen, für jeden einzelnen Baum noch weitere Angaben zu berechnen, 
        z.B. die Menge des gebundenen CO<sub>2</sub>.
        </p>
        <p>
        Inzwischen funktioniert die App bereits in vielen Städten. Die entsprechenden Daten aus den Baumkatastern
        und anderen öffentlichen Datenbanken wurden dazu gesammelt, strukturiert und aufbereitet, so dass sie für die
        App einheitlich nutzbar sind. Nicht alle Daten sind in jeder Stadt vergfügbar - so findet man in
        Hamburg beispielsweise keine Messwerte für die Baumhöhe.
        Daher können nicht in allen Städten alle Zusatzinformationen berechnet werden.
        </p>
        <p>
        Wie geht es weiter? Wir haben noch viele Ideen, um Hallo Baum zu erweitern und zu verbessern.
        Hast du auch eine? Oder vielleicht eine Frage? Dann schreib mir gerne eine Nachricht an <br />
        <a href="mailto:kontakt@hallobaum.de" rel="nofollow">kontakt@hallobaum.de</a>.
        </p>
        <p>
        Hallo Baum wird komplett in Eigenleistung entwickelt - das kostet viel Zeit und Geld.
        Wenn dir das Projekt gefällt, unterstütze es doch einfach mal mit einen Kaffee über Ko-Fi (externer Link):
        </p>
        <a target="_new" className="no-link-icon" href="https://ko-fi.com/thomasamthor" rel="nofollow"><KoFiButton /></a>
        <p>
        Viel Spaß beim Erkunden deiner Baum-Nachbarschaft!
        </p>

        <br />
        </div>
    </div>
  );
}
